<template>
  <div class="nk-block-head nk-block-head-lg">
    <div class="nk-block-between">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title mb-0">{{ title }}</h4>
        <div class="nk-block-des">
          <p class="mb-0">Manage your {{ Settings._APP_NAME }} Users</p>
        </div>
      </div>
    </div>
  </div>
  <!-- .nk-block-head -->
  <div class="nk-block" v-if="false">
    <div class="card">
      <div class="card-inner-group">
        <div class="card-inner p-0">
          <div class="between-center flex-wrap flex-md-nowrap g-3">
            <div class="nk-block-text">
              <h6>Users</h6>
              <p>User Info</p>
            </div>
            <div class="nk-block-actions">
              <a href="#" class="btn btn-primary">Edit</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const title = ref("Users");
    const store = useStore();
    const Settings = computed(() => store.getters.settings);
    return {
      title,
      Settings,
    };
  },
};
</script>
